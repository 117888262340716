<template>
  <div class="login-bg">
    <!-- <v-btn
      class="userLogin elevation-0 transparent--text"
      color="transparent"
      @click="$router.push('/login/origin')"
      >123</v-btn
    > -->
    <v-row justify="center" style="height: 100%" no-gutters>
      <v-col cols="11" sm="10">
        <v-img :src="LoginImage" contain class="bg-image" minHeight="360">
          <div class="welcome">歡迎使用{{ settings.title }}</div>
          <div class="loginArea">
            <v-btn
              class="mb-4"
              fab
              dark
              color="#556172"
              @click="loginEdu"
              :small="$vuetify.breakpoint.sm"
            >
              <v-icon dark> mdi-town-hall </v-icon>
            </v-btn>
            <div>透過教育網登入</div>
          </div>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Edu from '@/api/Edu'
import settings from '@/settings'

export default {
  data() {
    return {
      settings: settings
    }
  },
  computed: {
    LoginImage() {
      return require(`@/assets/image/LoginImage${
        this.$vuetify.breakpoint.xs ? 'M' : ''
      }.png`)
    }
  },
  methods: {
    loginEdu() {
      console.log(JSON.stringify(process.env))
      console.log(settings.edu.city)
      if (settings.edu.city === 'tainan') Edu.authTainan()
      else Edu.auth()
    }
  }
}
</script>

<style lang="scss" scoped>
.login-bg {
  width: 100vw;
  height: 100vh;
  background: #eff9ff;
  padding: 100px;
}

.bg-image {
  position: relative;
  top: top;
  top: 50%;
  margin-top: -30%;
}

.welcome {
  color: #165779;
  font-weight: bold;
  position: absolute;
  left: calc(53% - 104px);
  bottom: 56%;
}

.loginArea {
  text-align: center;
  position: absolute;
  left: calc(52.5% - 63px);
  bottom: calc(27% - 49.2px);
  font-weight: bold;
  color: #556172;
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .login-bg {
    padding: 0;
  }

  .bg-image {
    top: 31%;
  }

  .welcome {
    left: calc(48% - 104px);
    bottom: 58%;
  }

  .loginArea {
    left: calc(45% - 54px);
    bottom: calc(30% - 40px);
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .login-bg {
    padding: 0 10px;
  }

  .loginArea {
    font-size: 16px;
    left: calc(52.5% - 54px);
    bottom: calc(27% - 40px);
  }
}

.userLogin {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
